import '@rainbow-me/rainbowkit/styles.css'

import {
    getDefaultWallets,
    RainbowKitProvider,
    lightTheme,
    RainbowKitAuthenticationProvider,
    createAuthenticationAdapter,
    connectorsForWallets,
} from '@rainbow-me/rainbowkit'

import {
    argentWallet,
    trustWallet,
    ledgerWallet,
} from '@rainbow-me/rainbowkit/wallets'

import { configureChains, createClient, WagmiConfig } from 'wagmi'
import * as chain from 'wagmi/chains'
// import { alchemyProvider } from 'wagmi/providers/alchemy';
import { publicProvider } from 'wagmi/providers/public'
// import { SiweMessage } from 'siwe';

import { Web3AmplifyAuthProvider } from './providers/sessionProvider.tsx'

import React from 'react'
import ReactDOM from 'react-dom'
// import registerServiceWorker from './registerServiceWorker';
import { unregister } from './registerServiceWorker'

import { BrowserRouter, Routes } from 'react-router-dom'
import './assets/base.css'
import Main from './App/Main'
import configureStore from './config/configureStore'
import { Provider } from 'react-redux'
import { Amplify } from 'aws-amplify'
import * as Sentry from '@sentry/react'
import { BrowserTracing } from '@sentry/tracing'

Amplify.configure({
    Auth: {
        region: 'us-east-1',
        userPoolId: process.env.REACT_APP_COGNITO_POOL_ID,
        userPoolWebClientId: process.env.REACT_APP_COGNITO_CLIENT_ID,
        authenticationFlowType: 'CUSTOM_AUTH',
    },
})

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    integrations: [new BrowserTracing()],
    tracesSampleRate: 1.0,
})

const store = configureStore()
const rootElement = document.getElementById('root')

const { chains, provider, webSocketProvider } = configureChains(
    [chain.mainnet, chain.polygon, chain.goerli, chain.polygonMumbai],
    [publicProvider()]
)

const { wallets } = getDefaultWallets({
    appName: 'Vinchi',
    chains,
})

const connectors = connectorsForWallets([
    ...wallets,
    {
        groupName: 'Other',
        wallets: [
            argentWallet({ chains }),
            trustWallet({ chains }),
            ledgerWallet({ chains }),
        ],
    },
])

const wagmiClient = createClient({
    autoConnect: true,
    connectors,
    provider,
    webSocketProvider,
})

const renderApp = (Component) => {
    ReactDOM.render(
        <WagmiConfig client={wagmiClient}>
            <Web3AmplifyAuthProvider>
                <RainbowKitProvider
                    chains={chains}
                    theme={lightTheme({
                        accentColor: '#545cd8',
                        accentColorForeground: 'white',
                        borderRadius: 'small',
                        fontStack: 'system',
                    })}
                >
                    <Provider store={store}>
                        <BrowserRouter>
                            <Component />
                        </BrowserRouter>
                    </Provider>
                </RainbowKitProvider>
            </Web3AmplifyAuthProvider>
        </WagmiConfig>,
        rootElement
    )
}

renderApp(Main)

if (module.hot) {
    module.hot.accept('./App/Main', () => {
        const NextApp = require('./App/Main').default
        renderApp(NextApp)
    })
}
unregister()

// registerServiceWorker();
