import { Routes, Route, Navigate, useNavigate } from 'react-router-dom'
import React, { Suspense, lazy, Fragment, useState, useEffect } from 'react'

import { ToastContainer } from 'react-toastify'

import { useWeb3AmplifyAuthProvider } from '../../providers/sessionProvider.tsx'

const Dashboards = lazy(() => import('../../DemoPages/Dashboards'))

const Widgets = lazy(() => import('../../DemoPages/Widgets'))
const Elements = lazy(() => import('../../DemoPages/Elements'))
const Components = lazy(() => import('../../DemoPages/Components'))
const Charts = lazy(() => import('../../DemoPages/Charts'))
const Forms = lazy(() => import('../../DemoPages/Forms'))
const Tables = lazy(() => import('../../DemoPages/Tables'))

const Login = lazy(() => import('../../Pages/Login'))

const AppMain = () => {
    const { state } = useWeb3AmplifyAuthProvider()

    const navigate = useNavigate()
    useEffect(() => {
        if (state === 'unauthenticated') {
            navigate('/login')
        }
    }, [state])

    return (
        <Fragment>
            <Routes>
                <Route index element={<Navigate to="dashboards" />} />
                <Route
                    path="/login"
                    element={
                        <Suspense
                            fallback={
                                <div className="loader-container">
                                    <div className="loader-container-inner">
                                        <h6 className="mt-5">
                                            Please wait while we load all the
                                            Components examples
                                            <small>
                                                Because this is a demonstration
                                                we load at once all the
                                                Components examples. This
                                                wouldn't happen in a real live
                                                app!
                                            </small>
                                        </h6>
                                    </div>
                                </div>
                            }
                        >
                            <Login />
                        </Suspense>
                    }
                />

                <Route
                    path="/components/*"
                    element={
                        <Suspense
                            fallback={
                                <div className="loader-container">
                                    <div className="loader-container-inner">
                                        <h6 className="mt-5">
                                            Please wait while we load all the
                                            Components examples
                                            <small>
                                                Because this is a demonstration
                                                we load at once all the
                                                Components examples. This
                                                wouldn't happen in a real live
                                                app!
                                            </small>
                                        </h6>
                                    </div>
                                </div>
                            }
                        >
                            <Components />
                        </Suspense>
                    }
                />

                <Route
                    path="/forms/*"
                    element={
                        <Suspense
                            fallback={
                                <div className="loader-container">
                                    <div className="loader-container-inner">
                                        <h6 className="mt-5">
                                            Please wait while we load all the
                                            Forms examples
                                            <small>
                                                Because this is a demonstration
                                                we load at once all the Forms
                                                examples. This wouldn't happen
                                                in a real live app!
                                            </small>
                                        </h6>
                                    </div>
                                </div>
                            }
                        >
                            <Forms />
                        </Suspense>
                    }
                />

                <Route
                    path="/charts/*"
                    element={
                        <Suspense
                            fallback={
                                <div className="loader-container">
                                    <div className="loader-container-inner">
                                        <h6 className="mt-3">
                                            Please wait while we load all the
                                            Charts examples
                                            <small>
                                                Because this is a demonstration
                                                we load at once all the Charts
                                                examples. This wouldn't happen
                                                in a real live app!
                                            </small>
                                        </h6>
                                    </div>
                                </div>
                            }
                        >
                            <Charts />
                        </Suspense>
                    }
                />

                <Route
                    path="/tables/*"
                    element={
                        <Suspense
                            fallback={
                                <div className="loader-container">
                                    <div className="loader-container-inner">
                                        <h6 className="mt-5">
                                            Please wait while we load all the
                                            Tables examples
                                            <small>
                                                Because this is a demonstration
                                                we load at once all the Tables
                                                examples. This wouldn't happen
                                                in a real live app!
                                            </small>
                                        </h6>
                                    </div>
                                </div>
                            }
                        >
                            <Tables />
                        </Suspense>
                    }
                />

                <Route
                    path="/elements/*"
                    element={
                        <Suspense
                            fallback={
                                <div className="loader-container">
                                    <div className="loader-container-inner">
                                        <h6 className="mt-3">
                                            Please wait while we load all the
                                            Elements examples
                                            <small>
                                                Because this is a demonstration
                                                we load at once all the Elements
                                                examples. This wouldn't happen
                                                in a real live app!
                                            </small>
                                        </h6>
                                    </div>
                                </div>
                            }
                        >
                            <Elements />
                        </Suspense>
                    }
                ></Route>

                <Route
                    path="/widgets/*"
                    element={
                        <Suspense
                            fallback={
                                <div className="loader-container">
                                    <div className="loader-container-inner">
                                        <h6 className="mt-3">
                                            Please wait while we load all the
                                            Dashboard Widgets examples
                                            <small>
                                                Because this is a demonstration
                                                we load at once all the
                                                Dashboard Widgets examples. This
                                                wouldn't happen in a real live
                                                app!
                                            </small>
                                        </h6>
                                    </div>
                                </div>
                            }
                        >
                            <Widgets />
                        </Suspense>
                    }
                />

                <Route
                    path="/dashboards/*"
                    element={
                        <Suspense
                            fallback={
                                <div className="loader-container">
                                    <div className="loader-container-inner">
                                        <h6 className="mt-3">
                                            Please wait while we load all the
                                            Dashboards examples
                                            <small>
                                                Because this is a demonstration,
                                                we load at once all the
                                                Dashboards examples. This
                                                wouldn't happen in a real live
                                                app!
                                            </small>
                                        </h6>
                                    </div>
                                </div>
                            }
                        >
                            <Dashboards />
                        </Suspense>
                    }
                />
            </Routes>
            <ToastContainer />
        </Fragment>
    )
}

export default AppMain
