import React, {
    createContext,
    ReactNode,
    useCallback,
    useContext,
    useEffect,
    useMemo,
    useRef,
    useState,
} from 'react'
import { useAccount, useEnsName } from 'wagmi'
import { signMessage } from '@wagmi/core'
import { Auth } from 'aws-amplify'
import * as Sentry from '@sentry/react'

export type AuthenticationStatus =
    | 'loading'
    | 'authenticating'
    | 'unauthenticated'
    | 'authenticated'
    | 'declined'
    | 'error'

export interface IAuthenticationContext {
    state: AuthenticationStatus
    address: `0x${string}` | undefined
    ensName: string | null | undefined
}

const AuthenticationContext = createContext<IAuthenticationContext>({
    state: 'loading',
    ensName: null,
    address: undefined,
})

interface Web3AmplifyAuthProviderProps {
    children: ReactNode
}

function breadcrumb(message: string) {
    Sentry.addBreadcrumb({
        category: 'auth',
        message,
        level: 'info',
    })
}

export function Web3AmplifyAuthProvider({
    children,
}: Web3AmplifyAuthProviderProps) {
    const [state, setState] = useState<AuthenticationStatus>('loading')
    const [cognitoUser, setCognitoUser] = useState()

    const ethAccount = useAccount()

    const { data: ensName } = useEnsName({
        address: ethAccount.address,
    })

    const signIn = useCallback(
        (address) => {
            breadcrumb('Singin into Cognito with address ' + address)
            return Auth.signIn(address)
                .then((user) => {
                    breadcrumb(
                        'Auth.signIn success, user: ' + user.getUsername()
                    )
                    return user
                })
                .catch((error) => {
                    breadcrumb('Auth.signIn error, user: ' + error)
                    if (
                        error &&
                        error.message &&
                        error.message.includes('[404]')
                    ) {
                        const randomValues = new Uint8Array(30)
                        window.crypto.getRandomValues(randomValues)
                        const password = Array.from(randomValues)
                            .map((nr) => {
                                return nr.toString(16).padStart(2, '0')
                            })
                            .join('')

                        const params = {
                            username: address,
                            password,
                        }
                        return Auth.signUp(params)
                            .then((signup) => {
                                breadcrumb('Auth.signUp OK. ' + signup)
                                return signIn(address)
                            })
                            .catch((error) => {
                                Sentry.captureException(error, {
                                    tags: {
                                        casue: 'signin-flow',
                                        step: 'signup-error',
                                    },
                                })
                                setState('error')
                            })
                    } else {
                        Sentry.captureException(error, {
                            tags: {
                                casue: 'signin-flow',
                                step: 'non-signup',
                            },
                        })
                    }
                })
        },
        [setState]
    )

    const signEthereumMessage = useCallback(
        (cognitoUser) => {
            const messageToSign = cognitoUser.challengeParam.message
            breadcrumb('Sing Message with PK: ' + messageToSign)
            return signMessage({
                message: messageToSign,
            })
                .then((signature) => {
                    breadcrumb('Sing Message OK')
                    return { cognitoUser, signature }
                })
                .catch((error) => {
                    breadcrumb('User declined message signing: ' + error)
                    setState('declined')
                    throw error
                })
        },
        [setState]
    )

    // Get Cognito user on wallet connect
    useEffect(() => {
        // Wait for wallet get connected
        if (ethAccount.status !== 'connected') {
            return
        }
        // Proceed for loading only
        if (state !== 'loading') {
            return
        }
        setState('authenticating')

        Auth.currentAuthenticatedUser()
            .then((user) => {
                setCognitoUser(user)
                setState('authenticated')
            })
            .catch((err) => {
                // force UI to navigate to the login screen
                setState('unauthenticated')
                breadcrumb('No authenticated Cognito user')
                // We're getting here only when address is resolved
                signIn(ethAccount.address)
                    .then(signEthereumMessage)
                    .then(({ cognitoUser, signature }) => {
                        breadcrumb('Sending auth challenge')
                        return Auth.sendCustomChallengeAnswer(
                            cognitoUser,
                            signature
                        )
                            .then((answer) => {
                                breadcrumb(
                                    'Custom auth challenge received ' + answer
                                )
                            })
                            .catch((error) => {
                                breadcrumb('Custom auth challenge error')
                                setState('error')
                                throw error
                            })
                    })
                    .then(() => {
                        breadcrumb(
                            'getting current authenticated user after custom challenge'
                        )
                        return Auth.currentAuthenticatedUser().catch(
                            (error) => {
                                breadcrumb('error getting user ')
                                setState('error')
                                throw error
                            }
                        )
                    })
                    .then((cognitoUser) => {
                        breadcrumb('User successfully authenicated')
                        setCognitoUser(cognitoUser)
                        setState('authenticated')
                    })
                    .catch((error) => {
                        Sentry.captureException(error, {
                            tags: {
                                casue: 'signin-flow',
                            },
                        })
                    })
            })
    }, [
        ethAccount.status,
        cognitoUser,
        signIn,
        setCognitoUser,
        setState,
        state,
    ])

    const prevAccountStatus = useRef<
        'disconnected' | 'connected' | 'reconnecting' | 'connecting'
    >('disconnected')

    // Add an effect to sign out user if user disconnect the wallet
    useEffect(() => {
        if (ethAccount.status === 'disconnected') {
            setState('unauthenticated')
            setCognitoUser(undefined)
            Auth.signOut({ global: true }).catch((err) => {
                Sentry.captureException(err, {
                    tags: {
                        casue: 'signout',
                    },
                })
            })
        }
        // transition unauthenticated -> loading if wallet entering into connected state
        if (
            prevAccountStatus.current !== 'connected' &&
            ethAccount.status === 'connected' &&
            state === 'unauthenticated'
        ) {
            setState('loading')
        }
        prevAccountStatus.current = ethAccount.status
    }, [ethAccount.status, state, setState])

    // Set user address
    useEffect(() => {
        if (state !== 'authenticated') {
            return
        }
        Sentry.setContext('user', {
            address: ethAccount.address,
        })
    }, [state, ethAccount.address])

    return (
        <AuthenticationContext.Provider
            value={useMemo(
                () => ({ state, ensName, address: ethAccount.address }),
                [state, ensName, ethAccount.address]
            )}
        >
            {children}
        </AuthenticationContext.Provider>
    )
}

export function useWeb3AmplifyAuthProvider(): IAuthenticationContext {
    const context: IAuthenticationContext =
        useContext(AuthenticationContext) ?? {}

    if (!context) {
        throw new Error('No authentication adapter found')
    }

    return context
}
